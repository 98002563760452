code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.site-page-header-ghost-wrapper {
  padding: 24px;
  background-color: #f5f5f5;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

.modal {
  height: auto;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}

.catalog-div {
  max-height: 65vh;
  overflow: auto;
  padding: 10px 15px 0 1px;
}

/* Top bar */
.topbar {
  position: "absolute";
  display: "flex";
  width: 100%;
  padding-bottom: 3%;
}

.table-div {
  max-height: 65vh;
  min-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}

.table-header {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

/* Patient edit details form, readonly styling for input fields -- START */
.ui.form.patientForm input[type="text"]:read-only {
  background-color: #f5f5f5;
}

.ui.form.patientForm input[type="text"]:read-only:focus {
  background-color: #f5f5f5;
}

.ui.form.patientForm input[type="text"]:read-only:hover {
  background-color: #f5f5f5;
  cursor: default;
}

.ui.form.patientForm .dobCalendar input[type="text"]:read-only {
  background-color: #ffffff;
}

/* Patient edit details form , readonly styling for input fields --- END */

/* Notes Styling -- START */

/* Style for the whole child component (align to the left), same line as the parent note.*/
.ui.comments .comment .comments {
  margin: 0 0 0.5em 0em !important;
  padding: 0.5em 0 0.5em 0em !important;
}

.ui.reply.form {
  margin: 0 0 0 0;
}
/* Style for a child (reply textbox for notes)*/
.ui.comments .comment .reply.form {
  width: 100%;
  margin-top: 0.25em !important;
  font-size: 1rem;
}
/* Styling for the child textbox, making it compact to fo fit well below the parent (reply textbox for notes)*/
#form-input-control-reply-comment {
  padding: 0.45em 1em !important;
}

#form-button-reply-comment {
  position: relative;
  padding-left: 2.307143em !important;
  padding-right: 1em !important;
  height: 30px;
  text-align: center;
}

.noteattr_display_text {
  margin: 0.5em 0 0 0;
  font-size: 0.98rem;
}

.note_type_text {
  color: #4183c4;
  font-size: 1rem;
}
/* Notes styling -- END */

/* Banner Flags styling -- START */
/* Flags margin*/
.list .item.cb_margin_0_3 {
  margin-left: 0.3rem !important;
}
.cb_floatleft {
  float: left;
}
.cb_floatright {
  float: right;
}
.cb_height_30 {
  height: 30px;
}
.cb_margin_left_0 {
  margin-left: 0rem !important;
}
.cb_margin_left_5 {
  margin-left: 5rem !important;
}
.cb_margin_left_10 {
  margin-left: 10rem !important;
}
/* Banner Flags styling -- END */

.cb_textalign_left {
  text-align: left !important;
}

.cb_fullwidth {
  width: 100% !important;
}

.cb_marginbottom_10 {
  margin-bottom: 10 !important;
}

.cb_margintop_10 {
  margin-top: 10 !important;
}

.cb_fontsize_15 {
  font-size: 15px;
}

/*Side bar pusher wrap*/
.cb_sidebar_block .visible.sidebar.menu + .pusher {
  transform: scale(1) !important;
}

.cb_sidebar_block .visible.sidebar.menu + .pusher {
  width: calc(100vw - 470px) !important;
  float: right !important;
}

.cb_sidebar_block .visible.sidebar.menu {
  width: 400px !important;
}

.cb_home .segment.active.tab {
  min-height: calc(100vh - 30%) !important;
}
.cb_sidebar_block.pushable {
  min-height: 80vh !important;
}
.cb_label_invertcolor {
  color: white !important;
  background-color: black;
}

.cb_primarybutton {
  background-color: #2185d0 !important;
  color: #fff !important;
  text-shadow: none !important;
  background-image: none !important;
}

/* */
.cb_sidebar_block .ui.labeled.icon.menu {
  text-align: left !important;
}

/* Prfoile Data on the last Sidebar*/
.cb_sidebar_block .ui.labeled.icon.menu .item > .icon:not(.dropdown) {
  display: table-cell !important;
  float: left !important;
  font-size: 1.1em !important;
}

/* Tasks Styling...still not in use*/

.ui.relaxed.list .item.tasks_list_hover {
  cursor: "pointer" !important;
  background-color: "#add8e6" !important;
  padding: "6px" !important;
  border-radius: "6px" !important;
  margin: "5px 0px" !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.ui.relaxed.list .item.cursor_pointer {
  cursor: "pointer" !important;
}
